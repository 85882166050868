import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {useDispatch, useSelector} from 'react-redux';

import {ApiRegistry, AudioContextProvider,} from 'shared';

import {HostContainer} from 'shared-ui';

import {
  addPlaylistItem,
  selectIsPlaying,
  selectPlaylistItems,
  selectSelectedIndex,
} from '../../../store/reducers/playlist';

import DefaultLayout from '../../layouts/DefaultLayout';
import PlayerBar from '../../components/PlayerBar';

import PlaylistGrid from '../../components/PlaylistGrid';
import demoMediaData from '../../../data/DemoMediaData';
import usePlayer from './usePlayer';

import Seo from '../../../app/Seo';
import Page from '../../components/Page';
import GetTheExtension from "../../components/GetTheExtension";

const RealTimeDemoPage = () => {
  const playerId = `website`;
  const [value, setValue] = React.useState('1');

  const [isPlayerInitialized, setIsPlayerInitialized] = useState(false);

  const dispatch = useDispatch();
  const playlistItems = useSelector(selectPlaylistItems);
  const selectedIndex = useSelector(selectSelectedIndex);
  const isPlaying = useSelector(selectIsPlaying);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const {
    handleOnPlay,
    handleOnPlayForIndex,
    handleOnNext,
    handleOnPrevious,
    callbackOnFinishPlaying,
  } = usePlayer();

  React.useEffect(() => {
    if (!playlistItems.length) {
      demoMediaData.forEach((demoMedia) => {
        dispatch(
            addPlaylistItem({
              path: demoMedia.path,
              title: demoMedia.title,
              type: demoMedia.type,
            })
        );
      });
    }
    return () => {
    };
  }, [dispatch, playlistItems]);

  React.useEffect(() => {
    ApiRegistry.getInstance().backgroundMediaPlayerService.createMediaPlayer(
        playerId,
        AudioContextProvider.getAudioContext(),
        callbackOnFinishPlaying
    );

    setIsPlayerInitialized(true);

    return () => {
    };
  }, []);


  /**
   * UNLOAD - NOT WORKING
   */
  // const handleBeforeUnload = () => {
  //   console.log(`handleBeforeUnload`)
  //   ApiRegistry.getInstance().backgroundMediaPlayerService.pause(playerId, selectedIndex);
  // };
  //
  // React.useEffect(() => {
  //   console.log(`inside useEffect for handleBeforeUnload`)
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  return (
      <>
        <Seo title='Live Demo'/>
        <DefaultLayout>
          <Page
              pageTitle='Real-time demo'
              // pageTitle='Live Demo'
              // pageSubtitle='(real-time processing)'
          >
            <Container
                sx={{
                  border: '0px solid green',
                  marginTop: '1rem',
                  marginBottom: '1rem',
                }}
            >
              <Grid
                  container
                  direction='row'
                  alignItems='flex-start'
                  justifyContent='center'
              >
                <Grid item xs={12}>
                  <PlayerBar
                      handleOnPlay={handleOnPlay}
                      handleOnNext={handleOnNext}
                      handleOnPrevious={handleOnPrevious}
                      isPlaying={isPlaying}
                  />
                </Grid>

                <Grid item sm={12} md={8} sx={{border: '0px solid red'}}>
                  <Box
                      m={"auto"}
                      sx={{
                        position: 'relative',
                        maxWidth: '650px',
                        minHeight: '400px',
                        // width: '650px',
                        // height: '600px',
                        // margin: 'auto',
                      }}
                  >
                    {isPlayerInitialized && (
                        <HostContainer
                            day={`day1`}
                            browser={undefined}
                            tabId={playerId}
                            settings={ApiRegistry.getInstance().defaultSettingsService.getDefaultSettings()}
                        ></HostContainer>
                    )}
                  </Box>
                </Grid>

                <Grid item sm={12} md={4} sx={{border: '0px solid blue'}}>
                  <PlaylistGrid
                      playlistItems={playlistItems}
                      selectedIndex={selectedIndex}
                      isPlaying={isPlaying}
                      handleOnPlayForIndex={handleOnPlayForIndex}
                  />

                  {/*<Box sx={{ width: '100%', typography: 'body1' }}>*/}
                  {/*  <TabContext value={value}>*/}
                  {/*    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>*/}
                  {/*      <TabList onChange={handleChange} aria-label="lab API tabs example"*/}
                  {/*               textColor="primary"*/}
                  {/*               indicatorColor="primary"*/}
                  {/*               centered*/}
                  {/*      >*/}
                  {/*        <Tab label="Audio" value="1" />*/}
                  {/*        <Tab label="Video" value="2" />*/}
                  {/*      </TabList>*/}
                  {/*    </Box>*/}
                  {/*    <TabPanel value="1">*/}
                  {/*      <PlaylistGrid*/}
                  {/*          playlistItems={playlistItems.filter(item => item.type === `audio`)}*/}
                  {/*          selectedIndex={selectedIndex}*/}
                  {/*          isPlaying={isPlaying}*/}
                  {/*          handleOnPlayForIndex={handleOnPlayForIndex}*/}
                  {/*      />*/}
                  {/*    </TabPanel>*/}
                  {/*    <TabPanel value="2">*/}
                  {/*      <PlaylistGrid*/}
                  {/*          playlistItems={playlistItems.filter(item => item.type === `video`)}*/}
                  {/*          selectedIndex={selectedIndex}*/}
                  {/*          isPlaying={isPlaying}*/}
                  {/*          handleOnPlayForIndex={handleOnPlayForIndex}*/}
                  {/*      />*/}
                  {/*    </TabPanel>*/}
                  {/*  </TabContext>*/}
                  {/*</Box>*/}
                </Grid>
              </Grid>

              <GetTheExtension/>
            </Container>
          </Page>
          <Box m={2}
               sx={{
                 border: '1px solid #808080',
                 position: "fixed",
                 right: "0",
                 bottom: "0",
                 visibility: (playlistItems.length > 0 && playlistItems[selectedIndex].type === `video`) ? `visible` : `hidden`
               }}>
            <Box p={1}>
              {playlistItems.length > 0 && playlistItems[selectedIndex].title}
            </Box>
            {/*<Card>*/}
            {/*  <CardContent sx={{*/}
            {/*    width: "358px",*/}
            {/*    height: "186px"*/}
            {/*  }}>*/}
            <Box sx={{
              width: "356px",
              height: "185px",
            }}>
              <div id={`videoPlaceholder-`}></div>
              <div id={`videoPlaceholder-0`}></div>
              <div id={`videoPlaceholder-1`}></div>
            </Box>
            {/*  </CardContent>*/}
            {/*</Card>*/}
          </Box>
        </DefaultLayout>
      </>
  );
};

export default RealTimeDemoPage;
