import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AudioContextProvider,
  IMediaContent,
  RemoteAudioMediaContent,
  RemoteVideoMediaContent,
} from 'shared';

import {
  decreaseSelectedIndex,
  increaseSelectedIndex,
  setSelectedIndex,
  selectIsPlaying,
  play,
  pause,
  selectPlaylistItems,
  selectSelectedIndex,
} from '../../../store/reducers/playlist';
import PlaylistItem from '../../../domain/playlist/PlaylistItem';
import PlaylistPlayerService from '../../../api-local/player/PlaylistPlayerService';

const convertPlaylistItemsToMediaContents = (playlistItems: PlaylistItem[]) => {
  const mediaContents: IMediaContent[] = [];

  playlistItems.forEach((playlistItem) => {
    let mediaContent: IMediaContent;

    if (playlistItem.path.endsWith(`mp4`)) {
      mediaContent = new RemoteVideoMediaContent(
        playlistItem.path,
        playlistItem.title,
        ``
      );
    } else {
      mediaContent = new RemoteAudioMediaContent(
        playlistItem.path,
        playlistItem.title,
        ``
      );
    }

    mediaContents.push(mediaContent);
  });

  return mediaContents;
};

// const usePlayer = (mediaPlayer: MediaPlayer) => {
const usePlayer = () => {
  const dispatch = useDispatch();
  const playlistItems = useSelector(selectPlaylistItems);
  const selectedIndex = useSelector(selectSelectedIndex);
  const isPlaying = useSelector(selectIsPlaying);
  const [enforcePlay, setEnforcePlay] = useState(false);

  const handleOnPlay = () => {
    // console.log(`handleOnPlay - selectedIndex:`, selectedIndex);
    // console.log(`handleOnPlay - isPlaying:`, isPlaying);

    AudioContextProvider.getAudioContext().resume();

    if (isPlaying) {
      dispatch(pause());
    } else {
      dispatch(play());
    }
  };

  const handleOnPlayForIndex = (index) => {
    // console.log(
    //   `handleOnPlayForIndex - selectedIndex / index, isPlaying:`,
    //   selectedIndex,
    //   index,
    //   isPlaying
    // );

    AudioContextProvider.getAudioContext().resume();

    if (selectedIndex === index) {
      if (isPlaying) {
        dispatch(pause());
      } else {
        dispatch(play());
      }
    } else {
      if (isPlaying) {
        dispatch(pause());
      }
      dispatch(setSelectedIndex({ index }));
      setEnforcePlay(true);
      // dispatch(play());
    }
  };

  const handleOnNext = () => {
    // console.log(`handleOnNext`);
    dispatch(increaseSelectedIndex());
  };

  const handleOnPrevious = () => {
    // console.log(`handleOnPrevious`);
    dispatch(decreaseSelectedIndex());
  };

  const callbackOnFinishPlaying = (event: Event) => {
    // console.log(`callbackOnFinishPlaying called in caller`);
    // console.log(`event`, event);

    handleOnNext();
  };

  useEffect(() => {
    PlaylistPlayerService.getInstance().assignPlaylistItems(playlistItems);
  }, [playlistItems]);

  useEffect(() => {
    // console.log(
    //   `useEffect - selectedIndex, isPlaying, enforcePlay`,
    //   selectedIndex,
    //   isPlaying,
    //   enforcePlay
    // );
    if (isPlaying || enforcePlay) {
      // console.log(`useEffect - INSIDE`);
      setEnforcePlay(false);
      dispatch(play());
    }
  }, [selectedIndex]);

  return {
    handleOnPlay,
    handleOnPlayForIndex,
    handleOnNext,
    handleOnPrevious,
    callbackOnFinishPlaying,
  };
};

export default usePlayer;
