import React from 'react';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';

import PlaylistItem from '../../../domain/playlist/PlaylistItem';
import PlaylistGridItem from './PlaylistGridItem';

interface PlaylistGridProps {
  playlistItems: PlaylistItem[];
  selectedIndex: number;
  isPlaying: boolean;
  handleOnPlayForIndex: any;
}

const PlaylistGrid = ({
  playlistItems,
  selectedIndex,
  isPlaying,
  handleOnPlayForIndex,
}: PlaylistGridProps) => {
  return (
    <Paper sx={{ width: '100%' }}>
      <List dense={true}>
        {playlistItems.map((playlistItem, index) => (
          <PlaylistGridItem
            key={index}
            playlistItem={playlistItem}
            index={index}
            selectedIndex={selectedIndex}
            isPlaying={isPlaying}
            handleOnPlayForIndex={handleOnPlayForIndex}
          />
        ))}
      </List>
    </Paper>
  );
};

export default PlaylistGrid;
