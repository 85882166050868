import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import {
  FastForwardRounded,
  FastRewindRounded,
  PauseRounded,
  PlayArrowRounded,
} from '@mui/icons-material';

interface PlayerControlsProps {
  handleOnPlay: any;
  handleOnNext: any;
  handleOnPrevious: any;
  isPlaying: boolean;
}

const PlayerControls = ({
  handleOnPlay,
  handleOnNext,
  handleOnPrevious,
  isPlaying,
}: PlayerControlsProps) => {
  const paused = true;
  const mainIconColor = '#F1F2F3';

  const setPaused = (isIt: boolean) => {};

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mt: -1,
      }}
    >
      <IconButton aria-label='previous song' onClick={handleOnPrevious}>
        <FastRewindRounded fontSize='large' htmlColor={mainIconColor} />
      </IconButton>
      <IconButton
        aria-label={paused ? 'play' : 'pause'}
        // onClick={() => setPaused(!paused)}
        onClick={handleOnPlay}
      >
        {isPlaying ? (
          <PauseRounded sx={{ fontSize: '3rem' }} htmlColor={mainIconColor} />
        ) : (
          <PlayArrowRounded
            sx={{ fontSize: '3rem' }}
            htmlColor={mainIconColor}
          />
        )}
      </IconButton>
      <IconButton aria-label='next song' onClick={handleOnNext}>
        <FastForwardRounded fontSize='large' htmlColor={mainIconColor} />
      </IconButton>
    </Box>
  );
};

export default PlayerControls;
