import React from 'react';
import Box from '@mui/material/Box';

import PlayerControls from './PlayerControls';
import TimeIndicator from './TimeIndicator';

interface PlayerBarProps {
  handleOnPlay: any;
  handleOnNext: any;
  handleOnPrevious: any;
  isPlaying: boolean;
}

const PlayerBar = ({
  handleOnPlay,
  handleOnNext,
  handleOnPrevious,
  isPlaying,
}: PlayerBarProps) => {
  return (
    <Box mb={2} sx={{
      // borderRadius: "10px", border: `1px solid #808080`,
      // maxWidth: "985px",
    }}>
      <PlayerControls
        handleOnPlay={handleOnPlay}
        handleOnNext={handleOnNext}
        handleOnPrevious={handleOnPrevious}
        isPlaying={isPlaying}
      />
      {/*<TimeIndicator />*/}
    </Box>
  );
};

export default PlayerBar;
