import DemoMedia from "./DemoMedia";

const demoMediaData: DemoMedia[] = [
  {
    path: '/assets/demo/audio/take-five.mp3',
    title: 'Take five',
    type: `audio`
  },
  {
    path: '/assets/demo/video/samsung-helicopter-clip.mp4',
    title: 'Samsung helicopter clip',
    type: `video`
  },
  {
    path: '/assets/demo/audio/money.mp3',
    title: 'Money',
    type: `audio`
  },
  {
    path: '/assets/demo/video/gravity-clip.mp4',
    title: 'Gravity trailer',
    type: `video`
  },
  {
    path: '/assets/demo/audio/apollonia.mp3',
    title: 'Apollonia',
    type: `audio`
  },
  {
    path: '/assets/demo/video/mi2.mp4',
    title: 'Mission Impossible III Scene',
    type: `video`
  },
  {
    path: '/assets/demo/audio/alabama-song.mp3',
    title: 'Alabama Song',
    type: `audio`
  }
];

export default demoMediaData;
