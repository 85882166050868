import React, { useEffect, useState } from 'react';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import {
  IMediaContent,
  RemoteAudioMediaContent,
  RemoteVideoMediaContent,
} from 'shared';

import PlaylistItem from '../../../domain/playlist/PlaylistItem';

interface PlaylistGridItemProps {
  playlistItem: PlaylistItem;
  index: number;
  selectedIndex: number;
  isPlaying: boolean;
  handleOnPlayForIndex: any;
}

const PlaylistGridItem = ({
  playlistItem,
  index,
  selectedIndex,
  isPlaying,
  handleOnPlayForIndex,
}: PlaylistGridItemProps) => {
  return (
    <ListItem>
      <ListItemButton
        selected={selectedIndex === index}
        onClick={() => handleOnPlayForIndex(index)}
      >
        <ListItemIcon>
          {isPlaying && selectedIndex === index ? (
            <PauseRounded />
          ) : (
            <PlayArrowIcon />
          )}
        </ListItemIcon>
        <ListItemText primary={playlistItem.title} />
      </ListItemButton>
    </ListItem>
  );
};

export default PlaylistGridItem;
